import { Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import Container from "../../common/Container";
import ServiceContent from "../../content/ServiceContent.json";
import IntroContent from "../../content/IntroContent.json";

import {
  FooterSection,
  Para,
  ContentTitle,
  Content,
  FooterRow,
  TagColumn,
  LogoContainer,
  LogoContent,
  LogoIcon,
  ContractorContainer,
  IconContainer,
  FooterColumn,
  CompanyColumn
} from "./styles";
import { Icon } from "common/Icon";

const Footer = () => {
  const navigate = useNavigate();

  const scrollUp = (url: string) => {
    navigate(url);
    const element = document.getElementById("header") as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const serviceContent = ServiceContent.section;

  return (
    <>
      <FooterSection>
        <Container>
          <FooterRow>
            <CompanyColumn lg={10} md={10} sm={12} xs={12}>
              <LogoContainer aria-label="footer-logo">
                <ContentTitle>{"Company"}</ContentTitle>
                <Icon src="logo.svg" width="201px" height="164px" />
                <LogoContent>{IntroContent.text}</LogoContent>
              </LogoContainer>
            </CompanyColumn>
            <FooterColumn lg={10} md={10} sm={12} xs={12}>
              <ContractorContainer>
                <ContentTitle>{"Approved Contractors"}</ContentTitle>
                <IconContainer>
                  <LogoIcon>
                    <Icon src="pmp.jpeg" width="201px" height="164px" />
                  </LogoIcon>
                  <LogoIcon>
                    <Icon src="biz.jpeg" width="201px" height="164px" />
                  </LogoIcon>
                  <LogoIcon>
                    <Icon src="bca.jpeg" width="201px" height="164px" />
                  </LogoIcon>
                </IconContainer>
              </ContractorContainer>
            </FooterColumn>
            <TagColumn lg={10} md={10} sm={12} xs={12}>
              <ContentTitle>{"Tags"}</ContentTitle>
              <Content>
                {serviceContent.map((service) => (
                  <Tag.CheckableTag
                    onClick={() => scrollUp(service.link)}
                    key={service.title}
                    checked={false}
                  >
                    <Para>{service.title}</Para>
                  </Tag.CheckableTag>
                ))}
              </Content>
            </TagColumn>
            <FooterColumn lg={10} md={10} sm={12} xs={12}>
              <ContentTitle>{"Address"}</ContentTitle>
              <Para>45 West Coast Avenue, West Coast Gardens</Para>
              <Para>Singapore</Para>
              <Para>128100</Para>
            </FooterColumn>
          </FooterRow>
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
