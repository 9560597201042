import styled from "styled-components";
import { Col, Row } from "antd";

export const FooterSection = styled("footer")`
  background: rgb(241, 242, 243);
  padding: 2.5rem 0;
`;

export const LogoContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const ContractorContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled("div")`
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const LogoContent = styled("div")`
  color: #18216d;
  width: 70%
`;

export const LogoIcon = styled("div")`
  padding-right 16px;

    @media screen and (max-width: 1024px) {
    padding-bottom: 16px;
  }
`;

export const ContentTitle = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;
`;

export const Content = styled("div")`
  display: flex;
  flex-direction: column;
  color: #18216d;

  @media screen and (max-width: 768px) {
    padding: 0.5rem 0;
  }

  .ant-tag {
    padding: 0;
  }
`;

export const FooterRow = styled(Row)`
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FooterColumn = styled(Col)`
  padding-bottom: 56px;
`;

export const TagColumn = styled(FooterColumn)`
  @media screen and (max-width: 768px) {
    padding: 1.5rem 0;
  }
`;

export const CompanyColumn = styled(FooterColumn)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Para = styled("div")`
  color: #18216d;
  font-size: 14px;
  width: 70%;
`;
