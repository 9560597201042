import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import Container from "../../common/Container";
import { Icon } from "../../common/Icon";
import { Button } from "../../common/Button";
import IntroContent from "../../content/IntroContent.json";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  HeaderContent,
  AddressSection,
  PhoneNumberSection,
  SocialMediaSection,
  NestedSection,
  EmailSection,
  HeaderRowTopSection,
  NextLineContent,
  TitleSection,
  BottomSection,
  LeftBottomSection,
  RightBottomSection,
} from "./styles";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  id: string;
}

const Header = ({ id }: HeaderProps) => {
  const navigate = useNavigate();
  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <div id={id}>
        <CustomNavLinkSmall onClick={() => navigate("/")}>
          <Span>{"Home"}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          onClick={() => {
            navigate("/", { state: { targetId: "about" } });
          }}
        >
          <Span>{"About"}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => navigate("/", { state: { targetId: "services" } })}
        >
          <Span>
            <Button>{"Services"}</Button>
          </Span>
        </CustomNavLinkSmall>
      </div>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <HeaderRowTopSection justify="end">
          <AddressSection>
            <Icon src="address.svg" width="20px" height="20px" />
            <NestedSection>
              45 West Coast Avenue, West Coast Gardens
            </NestedSection>
          </AddressSection>
          <PhoneNumberSection>
            <Icon src="phone.svg" width="20px" height="20px" />
            <NestedSection>83017445</NestedSection>
          </PhoneNumberSection>
          <SocialMediaSection>
            <NestedSection>
              <Icon src="facebook.svg" width="20px" height="20px" />
            </NestedSection>
            <NestedSection>
              <Icon src="twitter.svg" width="20px" height="20px" />
            </NestedSection>
            <NestedSection>
              <Icon src="instagram.svg" width="20px" height="20px" />
            </NestedSection>
          </SocialMediaSection>
          <EmailSection>
            <a href="mailto:enquiry@sunrisemep.sg">
              <NestedSection>enquiry@sunrisemep.sg</NestedSection>
            </a>
          </EmailSection>
        </HeaderRowTopSection>
        <BottomSection justify="space-between">
          <LeftBottomSection>
            <LogoContainer to="/" aria-label="homepage">
              <Icon src="logo.svg" width="201px" height="164px" />
            </LogoContainer>
            <TitleSection>
              <HeaderContent>{IntroContent.short_title}</HeaderContent>
              <NextLineContent>{IntroContent.next_line_title}</NextLineContent>
            </TitleSection>
          </LeftBottomSection>
          <RightBottomSection>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            <Burger onClick={toggleButton}>
              <Outline />
            </Burger>
            <Drawer closable={false} open={visible} onClose={toggleButton}>
              <Col style={{ marginBottom: "2.5rem" }}>
                <Label onClick={toggleButton}>
                  <Col span={12}>
                    <Menu>Menu</Menu>
                  </Col>
                  <Col span={12}>
                    <Outline />
                  </Col>
                </Label>
              </Col>
              <MenuItem />
            </Drawer>
          </RightBottomSection>
        </BottomSection>
      </Container>
    </HeaderSection>
  );
};

export default Header;
